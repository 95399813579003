/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from 'react';
import './src/styles/global.scss';
import WebFont from 'webfontloader';

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
};
export const onInitialClientRender = () => {
  WebFont.load({
    custom: {
      families: ['eudoxus sans', 'sans-serif'],
      urls: ['/fonts/EudoxusSans.css'],
    },
    classes: false,
  });
};

// export const wrapRootElement = ({ element }) => (
//   <ThemeProvider>{element}</ThemeProvider>
// );
